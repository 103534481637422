<template>
  <div v-if="getPercorso">
      <header>
        <h1 class="header--title"><router-link :to="{ name: 'EditorPercorsi'} ">Percorsi</router-link> / {{ getPercorso.titolo }}</h1>

        <div class="header--right">
            <router-link :to="{ name: 'EditorQuiz', params: { percorsoId: getPercorso.id} }" class="button button--small button--active">Quiz</router-link>
            <router-link :to="{ name: 'EditorLezioni', params: { percorsoId: getPercorso.id} }" class="button button--small">Lezioni</router-link>
        </div>
      </header>
      <div v-if="getPercorso.test">
      <div class="form">
          <div class="field-group flex">
              <div class="field col col-9">
                <label>Titolo test</label>
                <input type="text" v-model.lazy="getPercorso.test.nome" @change="sendQuiz">
              </div>
              <div class="field col col-3">
                <label>Pubblicato</label>
                <label class="switch">
                    <input type="checkbox" v-model.lazy="getPercorso.test.published" @change="sendQuiz">
                    <span class="slider round"></span>
                </label>
              </div>
          </div>
          <div v-if="getPercorso.test.test && getPercorso.test.test.length > 0">
           <draggable v-model="domande" handle=".handle" @change="log($event)" @end="onEnd">
              <transition-group>
                  <div v-for="(domanda, index) in domande" :key="index + 0">
                      <div class="field-group flex quizDomanda">
                        <div class="field col col-1">
                            <div class="handle">
                                <svg class="icon" width="22" height="22"><use xlink:href="#drag" /></svg>
                             </div>
                        </div>  
                        <div class="field col col-10">
                          <input type="text" v-model.lazy="domanda.domanda" @focus="setVisible(index)" @change="sendQuiz">
                          <div v-if="domanda.risposte">
                            <div v-for="(risposta, i) in domanda.risposte" :key="i" class="field-group flex quizRisposte" v-show="visibleItemIndex == index" :class="{'active' : visibleItemIndex == index}">
                                <div class="field col col-11" >
                                  <input type="text" v-model.lazy="risposta.text" @change="sendQuiz">
                                </div>
                                <div class="field col col-1">
                                  <label class="switch switch--small">
                                      <input type="checkbox" v-model.lazy="risposta.correct" @change="sendQuiz">
                                      <span class="slider round"></span>
                                  </label>
                                </div>
                            </div>
                          </div>

                        </div>
                        <div class="field col col-1">
                            <span @click="removeAt(index)">
                              <svg class="icon" width="22" height="22"><use xlink:href="#trash" /></svg>
                            </span>
                        </div> 
                      </div>
                      
                </div>
                  </transition-group>
                </draggable>
                <button class="button button--small button--outline" @click="add">Nuova domanda</button>

        </div>

      </div>

      <div class="form--action">
          <button class="button button-conferma" @click="sendQuiz">Salva</button>
      </div>
      </div>
      <div v-else>
        <div class="form">

        <div class="field-group flex">
              <div class="field col col-9">
                <label>Titolo test</label>
                <input type="text" v-model="emptyTest.nome">
              </div>
              <div class="field col col-3">
                <label>Pubblicato</label>
                <label class="switch">
                    <input type="checkbox" v-model="emptyTest.published">
                    <span class="slider round"></span>
                </label>
              </div>
          </div>

          <button @click="creaTest(getPercorso.id)">Inserisci le domande</button>

        </div>
      </div>

      </div>

</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState, mapActions } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'EditorQuiz',

  data: () => {
    return {
     visibleItemIndex: 9999,
     emptyTest: {
          nome:  '',
          published:  '',
          domande:  [ {domanda: " ", risposte: [{text: "", correct: "false"}, {text: "", correct: "false"}, {text: "", correct: "false"}]}],
          id: ''
      }
    }
  },

  components: {
    draggable
  },

  watch: {
      '$route.params': {
          handler(newValue) {
              const { percorsoId } = newValue
              this.$store.dispatch('percorsi/fetchPercorso', {
                id: percorsoId
              })
          },
          immediate: true,
      }

      
  },

  computed: {
    ...mapGetters([
      'isLogged',
      'isAdmin',
      'isEducatore',
      'isInsegnante',
      'isClasse',
      'user'
    ]),

    ...mapGetters('percorsi',[
        'getPercorso'
     ]),

     ...mapGetters('tests',[
        'getTest'
     ]),


      domande: {
      // getter
      get: function () {
        return this.getPercorso.test.test
      },
      // setter
      set: function (newValue) {
        return newValue
      }

    }

  },

  methods: {
    ...mapActions('percorsi',[
        'fetchPercorso',
    ]),

    ...mapActions('tests',[
        'addTest',
        'updateTest',
    ]),
    removeAt(i) {
      this.domande.splice(i, 1);
    },

    add: function() {
      this.domande.push({ domanda: " ", risposte: [{text: "", correct: "false"}, {text: "", correct: "false"}, {text: "", correct: "false"}]});
    },

    log: function(event) {
      //console.log(event)
    },

    onEnd: function(e) {
      this.domande.splice(e.newIndex, 0, this.domande.splice(e.oldIndex, 1)[0]);
      this.sendQuiz ();
    },

    setVisible (i) {
      this.visibleItemIndex = i
    },

    creaTest(id) {
      this.$store.state.success = null
      this.addTest({
        nome:  this.emptyTest.nome,
        published:  this.emptyTest.published,
        test:  this.emptyTest.domande,
        percorso_id: id
      }).then((response) => {
            this.$store.dispatch('percorsi/fetchPercorso', {
              id: id
            })
      }).catch(e => {
            this.$store.state.success = null
            this.$store.state.errors = e.response.data;
      })
  },

    sendQuiz () {
            this.$store.state.success = null
            this.updateTest({
                nome:  this.getPercorso.test.nome,
                published:  this.getPercorso.test.published,
                test:  this.domande,
                id: this.getPercorso.test.id
            }).then((response) => {
                //this.visibleItemIndex = 9999
                this.$store.dispatch('tests/fetchTest', {
                  test: this.getPercorso.test.id
                })
            }).catch(e => {
                this.$store.state.success = null
                this.$store.state.errors = e.response.data;
            })
        },

  }



}
</script>
